import PropTypes from "prop-types";
import React, { useState, useEffect } from 'react';
import style from './DataExplorerTable.module.scss';

/**
 * DataExplorerTable.jsx
 *
 * @summary Component to display Data Explorer Table.
 * 
 * @param {Object} props - Component props.
 * @prop {String} props.heading - Heading of component. Always render as h2.
 * @prop {Object.<string, string>} props.datasetHeadings - Dataset column headings to render table.
 * @param {Array.<Object.<string, string>>} props.dataSet - Data to render table. If not given or passed empty array or nullish value, component will render as no data state.
 * 
 * @description 
 *
 */

function DataExplorerTable({ datasetHeadings, dataSet }) {
  const [sortedData, setSortedData] = useState(dataSet);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  useEffect(() => {
    setSortedData(dataSet);
  }, [dataSet]);

  const columnHeadings = Object.keys(datasetHeadings).filter(heading => {
    return !sortedData.every(
      row => row[heading] === null || 
      row[heading] === undefined || 
      row[heading] === "-" || 
      row[heading] === "" ||
      !row[heading]
    );
  });

  const sortData = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    setSortConfig({ key, direction });
    const sortedArray = [...sortedData].sort((a, b) => {
      let aValue, bValue;
  
      if (key === '#') {
        aValue = sortedData.indexOf(a) + 1;
        bValue = sortedData.indexOf(b) + 1;
      } else {
        aValue = a[key];
        bValue = b[key];
      }
  
      if (key === "Value") {
        aValue = Number(aValue);
        bValue = Number(bValue);
      }
  
      if (aValue < bValue) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setSortedData(sortedArray);
  };

  return (
    <>
      <table className={style.DataExplorerTable}>
        <thead>
          <tr>
            <th className={[style.greyFill, style.countColumn].join(' ')}>
              {/* Count Column */}
            </th>
            {columnHeadings.map((heading, index) => (
              <th key={index} className={[style.darkFill, heading === 'Value' ? style.valueColumn : style.columnHeading].join(' ')}>
                <button onClick={() => sortData(heading)} className={style.columnHeadingButton}>
                  {/* <p>{datasetHeadings[heading]}</p> */}
                  <p>{heading}</p>

                  {sortConfig.key === heading && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      style={{
                        transform: sortConfig.direction === 'ascending' ? 'rotate(0deg)' : 'rotate(180deg)',
                      }}
                    >
                      <mask id="mask0_10479_16854" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                        <rect x="24" y="24" width="24" height="24" transform="rotate(-180 24 24)" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_10479_16854)">
                        <path d="M13.325 17.412L17.6282 13.1055L19.4805 14.9718L12 22.4523L4.50547 14.9718L6.37172 13.0945L10.675 17.412L10.675 11.2543L13.325 11.2543L13.325 17.412ZM13.325 6.16952L13.325 9.25427L10.675 9.25427L10.675 6.16952L13.325 6.16952ZM13.325 1.51952L13.325 4.16952L10.675 4.16952L10.675 1.51952L13.325 1.51952Z" fill="white" />
                      </g>
                    </svg>
                  )}
                </button>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td className={[style.countColumn, style.greyFill].join(' ')}><p>{rowIndex + 1}</p></td>
              {columnHeadings.map((heading, colIndex) => (
                <td
                  key={colIndex}
                  className={heading === 'Value' ? style.valueCell : style.whiteFill}
                  title={row[heading]} // This will show the full text in a tooltip
                >
                  {row[heading]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

DataExplorerTable.propTypes = {
  datasetHeadings: PropTypes.objectOf(PropTypes.string),
  dataSet: PropTypes.arrayOf(
    PropTypes.shape({
      Entity: PropTypes.string,
      ReportingPeriod: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
      DataFieldName: PropTypes.string,
      Value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object
      ])
    })
  ),
};

export default DataExplorerTable;
