import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import style from './AnnualReportSearch.module.scss';
import {getAnnualReportSearch} from 'Services/Delivery';
import { Link, useLocation } from 'react-router-dom';
import parse from 'html-react-parser';

/**
 * AnnualReportBanner.jsx
 *
 * @summary AnnualReportSearch is a component that allows the user to search for chapters and sections for specific string contents
 *
 */
function AnnualReportSearch({annualReportGuid, setSearchData, bodyData, setBodyData, routes, frontpage = true}) {
  const { pathname } = useLocation();
  const [queryTermData, setQueryTermData] = useState('');
  const [exactMatchTerm, setExactMatchTerm] = useState(false);
  const [buttonLinks, setButtonLinks] = useState({});
  const [searchMessage, setSearchMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  // const [originalBodyData, setOriginalBodyData] = useState();
  const [firstSearch, setFirstSearch] = useState(true);

  const [fetchingSearchResults, setFetchingSearchResults] = useState(false);

  const retrieveStringFromSessionStorage = (key) => {
    try {
      const value = sessionStorage.getItem(key);
      if (value !== null) {
        return value;
      } else {
        console.log(`No value found for key "${key}" in session storage.`);
        return null;
      }
    } catch (error) {
      console.error("Error retrieving string from session storage:", error);
      return null;
    }
  };

  useEffect(() => {

    const urlData = retrieveDataFromURL();

    let queryTerm = "";
    let exactMatch = false;
  
    // Check URL data first
    if (urlData) {
      queryTerm = urlData.queryTerm || "";
      exactMatch = urlData.exactMatch || false;
      setIsOpen(true);
    } else {
      // Check session storage if URL data is empty
      const sessionStorageQueryTerm = retrieveStringFromSessionStorage('queryTermData');
      const sessionStorageExactMatch = retrieveStringFromSessionStorage('exactMatch');
  
      if (sessionStorageQueryTerm) {
        queryTerm = sessionStorageQueryTerm;
        setIsOpen(true);
      }
  
      if (sessionStorageExactMatch) {
        exactMatch = sessionStorageExactMatch === 'true';
        setIsOpen(true);
      }
    }
  
    // Assign values to state
    if (queryTerm != '') setQueryTermData(queryTerm);
    if (exactMatch != false) setExactMatchTerm(exactMatch);
  }, []); 

  useEffect(() => {
    const urlData = retrieveDataFromURL();

    if (urlData) {
      const queryTerm = urlData.queryTerm || "";
      const exactMatch = urlData.exactMatch || false;

      const urlQueryString = storeDataInURL(queryTerm,exactMatch);
      const searchData = JSON.parse(retrieveStringFromSessionStorage('searchResult'));

      const linkData = processSearchLinks(urlQueryString, searchData);

      if (linkData){
        setButtonLinks(linkData);

        setSearchMessage(createSearchResultString(
          linkData.currentHit.currentSearchHit || 0, 
          linkData.currentHit.numberOfSearchHits || 0, 
          linkData.currentHit.totalHits || 0
        ));
        // setOriginalBodyData(bodyData);  
        // highlightText(bodyData.bodyNode, queryTerm, exactMatch);
        getAnnualReportSearchResult(bodyData.bodyNode);
      }
    }
    else if (!frontpage && !firstSearch){
      searchAnnualReport();
    }
  }, [pathname]);


  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  function searchAnnualReport (){
    setFetchingSearchResults(true);
    let bodyNode;
    if (!frontpage) {
      if (firstSearch) {
        // setOriginalBodyData(bodyData);
        bodyNode = bodyData.bodyNode;
        setFirstSearch(false);        
      } 
      // else {
      //   bodyNode = originalBodyData.bodyNode;
      // }
        bodyNode = bodyData.bodyNode;
    }

    
    if (!queryTermData || queryTermData == "") {

      if (!frontpage) {
        setBodyData(prevState => ({...prevState, 
          bodyNode: bodyNode
        }));

      }

      setSearchData({});
      setButtonLinks({});

      setSearchMessage('Blank search term is not allowed.');
      setBodyData(prevState => ({...prevState, 
        bodyNode: bodyNode
      }));
      setBodyData(prevState => ({...prevState, 
        
      }));
      return;
    }
    getAnnualReportSearchResult(bodyNode);
  }

  function getAnnualReportSearchResult (bodyNode){
    getAnnualReportSearch( annualReportGuid, queryTermData, exactMatchTerm).then(result => {

      storeInSessionStorage('searchResult',JSON.stringify(result));
      setSearchData(result);
      
      if (result.TotalHits <= 0) {
        setSearchMessage("No search results found matching your search term.");
      }

      if (!frontpage){
        setBodyData(prevState => ({...prevState, 
          bodyNode: highlightText(bodyNode,queryTermData, exactMatchTerm)
        }));

        const urlQueryString = storeDataInURL(queryTermData,exactMatchTerm);
        storeInSessionStorage('exactMatch', exactMatchTerm);
        storeInSessionStorage('queryTermData', queryTermData);

        const linkData = processSearchLinks(urlQueryString, result);
  
        setButtonLinks(linkData);
        
        if (linkData){
          setSearchMessage(createSearchResultString(
            linkData.currentHit.currentSearchHit || 0, 
            linkData.currentHit.numberOfSearchHits || 0, 
            linkData.currentHit.totalHits || 0
          ));
        }
      } else {
        storeInSessionStorage('exactMatch', exactMatchTerm);
        storeInSessionStorage('queryTermData', queryTermData);
        setSearchMessage(`Showing ${result.TotalHits} results in this Annual Report`);
      }

    });

    setTimeout(()=>{
      setFetchingSearchResults(false);
    },250);

  }

  function storeDataInURL(dataString, exactMatch) {
    const queryString = `?searchTerm=${encodeURIComponent(dataString)}&exactMatch=${exactMatch}`;
    window.history.pushState({}, '', window.location.pathname + queryString);
    return queryString;
  }

  const storeInSessionStorage = (key, value) => {
    try {
      sessionStorage.setItem(key, value);
    } catch (error) {
      console.error("Error storing string in session storage:", error);
    }
  };
  
  
  function retrieveDataFromURL() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
  
    const queryTerm = urlParams.get('searchTerm');
    const exactMatch = urlParams.get('exactMatch') === 'true';
    if (queryTerm === null){
      return;
    }
    const retrievedData = {
      queryTerm,
      exactMatch
    };

    return retrievedData;
  }

  function highlightText(content, searchTerm, exactMatchTerm) {
    const highlight = (node) => {
      if (!searchTerm) return node;
  
      if (typeof node === 'string') {
        let parts;
        if (exactMatchTerm) {
          // Use word boundaries (\b) to ensure we match whole words only
          const regex = new RegExp(`(\\b${searchTerm.split(' ').join('\\b \\b')}\\b)`, 'gi');
          parts = node.split(regex);
        } else {
          const words = searchTerm.split(' ').filter(Boolean);
          const regex = new RegExp(`(${words.join('|')})`, 'gi');
          parts = node.split(regex);
        }
  
        return parts.map((part, index) => {
          const matchType = exactMatchTerm
            ? part.toLowerCase() === searchTerm.toLowerCase()
            : searchTerm.split(' ').some(word => word.toLowerCase() === part.toLowerCase());
          return matchType ? (
            <mark key={index}>
              {part}
            </mark>
          ) : (
            part
          );
        });
      } else if (React.isValidElement(node)) {
        return React.cloneElement(node, {
          ...node.props,
          children: React.Children.map(node.props.children, (child) => highlight(child)),
        });
      } else {
        return node;
      }
    };
  
    const parseOptions = {
      replace: ({ name, attribs, children }) => {
        if (!children) return;
  
        return React.createElement(
          name,
          attribs,
          React.Children.map(children, (child) => highlight(child))
        );
      },
    };
  
    let highlightedContent;
  
    if (typeof content === 'string') {
      highlightedContent = parse(content, parseOptions);
    } else if (Array.isArray(content)) {
      highlightedContent = React.Children.map(content, (child) => highlight(child));
    } else if (content && content.bodyNode && Array.isArray(content.bodyNode)) {
      highlightedContent = React.Children.map(content.bodyNode, (child) => highlight(child));
    } else {
      console.error('Content is not in a valid format for highlighting.');
      return content;
    }
  
    return highlightedContent;
  }

  
  function processSearchLinks(urlQueryString, searchDataObject){
    function extractSearchResults(data, currentID, routes) {
      const results = [];
  
      data?.Chapters?.forEach(chapter => {
        if (chapter?.SearchResultHits > 0 || chapter?.System?.id === currentID) {
          const chapterRoute = routes?.find(route => route.chapterId === chapter.System.id);
          results.push({
              id: chapter.System.id,
              searchResultHit: chapter.SearchResultHits,
              route: chapterRoute ? chapterRoute.path : null 
          });
        }

        chapter.Sections.forEach(section => {
          if (section.SearchResultHits > 0 || section.System.id === currentID) {
            const sectionRoute = routes.flatMap(route => route.children).find(child => child.sectionId === section.System.id);
            results.push({
                id: section.System.id,
                searchResultHit: section.SearchResultHits,
                route: sectionRoute ? sectionRoute.path : null // Add path if available
            });
          }
        });
      });
  
      return results;
    }

    function getSurroundingIds(totalHits, results, currentId, urlQueryString) {
      const index = results.findIndex(result => result.id === currentId);
    
      const appendQueryString = (route) => {
        return route ? `${route}${urlQueryString}` : null;
      };

      const currentSearchHitCount = results.slice(0, index).reduce((total, result) => total + result.searchResultHit, 0);

      const surroundingIds = {
        firstHit: results[0].id !== currentId ? { id: results[0]?.id, route: appendQueryString(results[0]?.route) } : null,
        beforeHit: index > 0 ? { id: results[index - 1]?.id, route: appendQueryString(results[index - 1]?.route) } : null,
        currentHit: {
          id: currentId,
          route: appendQueryString(results[index]?.route),
          currentSearchHit: currentSearchHitCount || 0,
          numberOfSearchHits: results[index]?.searchResultHit || 0,
          totalHits: totalHits || 0,
        },
        afterHit: index < results.length - 1 ? { id: results[index + 1]?.id, route: appendQueryString(results[index + 1]?.route) } : null,
        lastHit: results[results.length - 1]?.id !== currentId ? { id: results[results.length - 1].id, route: appendQueryString(results[results.length - 1]?.route) } : null
      };

    
      return surroundingIds;
    }
    const filteredSearchResult = extractSearchResults(searchDataObject, bodyData?.system?.id, routes);
    return getSurroundingIds(searchDataObject.TotalHits, filteredSearchResult, bodyData.system.id, urlQueryString);

  }


  function createSearchResultString(currentSearchHit, numberOfSearchHits, totalHits) {
    function stringManipulator(firstNum, secondNum) {
      let start = firstNum + 1;

      if (secondNum < 2){
        return start;
      }
    
      let numbers = [];
      for (let i = 0; i < secondNum; i++) {
        numbers.push(start + i);
      }
    
      let result;
      if (numbers.length > 1) {
        result = numbers.slice(0, -1).join(', ') + ' and ' + numbers[numbers.length - 1];
      } else {
        result = numbers[0].toString(); 
      }
    
      return result;
    }

    if (!totalHits) {
      return `Showing result 0 of 0 results in this Annual Report`;
    } else if (numberOfSearchHits === 0) {
      return `Showing result - of ${totalHits} results in this Annual Report`;
    }

  
    const startHitNumber = currentSearchHit + 1; 
  
    const hitNumbers = [];
    for (let i = 0; i < numberOfSearchHits; i++) {
      hitNumbers.push(startHitNumber + i);
    }

    const hitNumberString = stringManipulator(currentSearchHit,numberOfSearchHits);
    const resultString = `Showing result ${hitNumberString} of ${totalHits} results in this Annual Report`;

    return resultString;
  }

  

  return (
    <>
      <div id={style.annualReportSearchContainer}>
        <button id={style.searchButton}  onClick={toggleAccordion}> 
          <h2 className={style.title}>Search this Annual Report</h2>

          <div className={`${style.buttonArrowWrapper} ${isOpen ? style.rotateArrow : ''}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M11.9984 8.5502L16.9199 13.4716C17.2349 13.7866 17.0118 14.3252 16.5663 14.3252H7.43055C6.98509 14.3252 6.76201 13.7866 7.07699 13.4716L11.9984 8.5502Z" fill="black"/>
            </svg>
          </div>
        </button>
        {isOpen && (
          <div id={style.searchBody}> 
            <div id={style.searchComponentContainer}> 
              <div id={style.textSearchContainer}> 
                <div id={style.innerTextSearchContainer}>
                  <input
                    type="text"
                    id="inputField"
                    value={queryTermData}
                    onKeyDown={(event)=>{if (event.key ==='Enter') {
                      searchAnnualReport();
                    } }}
                    onChange={(event)=>setQueryTermData(event.target.value)}
                    className={style.textField}
                  />
                  {queryTermData && queryTermData != '' && (
                    <button 
                      onClick={() => setQueryTermData('')}
                      className={style.crossButton}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                        <path d="M6.8998 19.45L5.0498 17.6L10.6498 12L5.0498 6.39999L6.8998 4.54999L12.4998 10.15L18.0998 4.54999L19.9498 6.39999L14.3498 12L19.9498 17.6L18.0998 19.45L12.4998 13.85L6.8998 19.45Z" fill="#515151"/>
                      </svg>
                    </button>
                  )}
                </div>
                
                <button 
                  onClick={searchAnnualReport}
                  className={style.searchButton}
                  disabled={fetchingSearchResults}
                > 
                  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 25 24" fill="none">
                    <path d="M20.0748 21.525L13.6748 15.15C13.1748 15.5 12.6041 15.7833 11.9628 16C11.3208 16.2167 10.6248 16.325 9.8748 16.325C7.9748 16.325 6.36247 15.6623 5.0378 14.337C3.71247 13.0123 3.0498 11.4 3.0498 9.49999C3.0498 7.59999 3.71247 5.98732 5.0378 4.66199C6.36247 3.33732 7.9748 2.67499 9.8748 2.67499C11.7915 2.67499 13.4081 3.33732 14.7248 4.66199C16.0415 5.98732 16.6998 7.59999 16.6998 9.49999C16.6998 10.25 16.5955 10.9417 16.3868 11.575C16.1788 12.2083 15.8915 12.7667 15.5248 13.25L21.9498 19.675L20.0748 21.525ZM9.8748 13.675C11.0415 13.675 12.0291 13.2707 12.8378 12.462C13.6458 11.654 14.0498 10.6667 14.0498 9.49999C14.0498 8.33332 13.6458 7.34565 12.8378 6.53699C12.0291 5.72899 11.0415 5.32499 9.8748 5.32499C8.7248 5.32499 7.74147 5.72899 6.9248 6.53699C6.10814 7.34565 5.6998 8.33332 5.6998 9.49999C5.6998 10.6667 6.10814 11.654 6.9248 12.462C7.74147 13.2707 8.7248 13.675 9.8748 13.675Z" fill="white"/>
                  </svg>
                </button>
              </div>

              <div id={style.toolingContainer}>
                <div id={style.checkButtonContainer}> 

                  <input
                    type="checkbox"
                    id="myCheckbox"
                    checked={exactMatchTerm}
                    onChange={(event) => setExactMatchTerm(event.target.checked)}
                  />
                  <label htmlFor="myCheckbox">Search for whole words/phrases only </label>
                </div>
                <div id={style.textContainer}>
                  <p>{searchMessage}</p>
                </div>

                {
                  !frontpage && (
                    <div>
                      {/* <p>When entering a new page please apply search in order to highlight text. PLACEHOLDER </p> */}
                    </div>
                  )
                }
              </div>
            </div>

            <>
              {!frontpage && (
                <div id={style.buttonContainer}> 
                  <Link 
                    to={buttonLinks?.beforeHit?.route || null}
                    className={style.nextButton}
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                      <path d="M12.4998 20.2L4.2998 12L12.4998 3.79999L14.3998 5.64999L9.3748 10.675H20.6998V13.325H9.3748L14.3998 18.35L12.4998 20.2Z" fill="white"/>
                    </svg>
                  </Link>

                  <Link 
                    to={buttonLinks?.firstHit?.route || null} 
                    className={style.endButton}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                      <path d="M14.4998 18.45L8.0498 12L14.4998 5.54999L16.3498 7.39999L11.7498 12L16.3498 16.6L14.4998 18.45Z" fill="black"/>
                    </svg>
                    First Result

                  </Link>

                  <Link 
                    to={buttonLinks?.lastHit?.route || null}
                    className={style.endButton}
                  >
                    Last Result
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                      <path d="M9.8998 18.45L8.0498 16.6L12.6498 12L8.0498 7.39999L9.8998 5.54999L16.3498 12L9.8998 18.45Z" fill="black"/>
                    </svg>
                  </Link>

                  <Link 
                    to={buttonLinks?.afterHit?.route || null} 
                    className={style.nextButton}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                      <path d="M12.4998 20.2L10.5998 18.35L15.6248 13.325H4.2998V10.675H15.6248L10.5998 5.64999L12.4998 3.79999L20.6998 12L12.4998 20.2Z" fill="white"/>
                    </svg>
                  </Link>
                </div>
              )}
            </>


          </div>
        )}
      </div>
    </>

  );
}


AnnualReportSearch.propTypes = {
  annualReportGuid: PropTypes.string,
  setSearchData: PropTypes.func,
  bodyData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  setBodyData: PropTypes.func,
  routes: PropTypes.array,
  frontpage: PropTypes.bool,
};

export default AnnualReportSearch;
