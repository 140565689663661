const DESKTOP_MIN = 1024;
const TABLET_MIN = 717;

export const CONSTANTS = {
  ESCAPE_KEY: 'Escape',
  ENTER_KEY: 'Enter',
  RIGHT_ARROW_KEY: 'ArrowRight',
  LEFT_ARROW_KEY: 'ArrowLeft',
  DESKTOP: {
    MIN: DESKTOP_MIN,
  },
  TABLET: {
    MAX: DESKTOP_MIN - 1,
    MIN: TABLET_MIN,
  },
  MOBILE: {
    MAX: TABLET_MIN - 1,
  },
  VIEW_MODE: {
    DESKTOP: 'desktop',
    TABLET: 'tablet',
    MOBILE: 'mobile',
  },
  ENV: {
    DEV: 'dev',
    UAT: 'uat',
    STAGE: 'stage',
    PROD: 'prod',
    EXPORT_PDF: 'pdf',
    EXPORT_WORD: 'word'
  },
};

export const TAG_STYLE_ENUMS = {
  HIGHLIGHT: 'highlight',
  GREY: 'grey',
  DARK: 'dark',
};

export const TAG_POSITION_ENUMS = {
  LEFT: 'left',
  RIGHT: 'right',
  BELOW: 'below',
};

export const MESSAGE_BOX_TYPE = {
  CONFIRMED: 'confirmed',
  INFORMATION: 'information',
  HELP: 'help',
  ERROR: 'error',
  WARNING: 'warning'
};

export const MANDATORY_REQUIREMENTS_CHAPTER_TITLE = 'List of requirements';
export const PAGE_NOT_FOUND_TITLE = '404 - Page not found';

export const KONTENT_EMPTY_DATA_VALUES = ['', '<p><br></p>']; // by default, Kontent RTE returns no body content entry as <p><br></p>


export const IS_FOR_EXPORT_PDF = process.env.REACT_APP_ENVIRONMENT?.toLowerCase() === CONSTANTS.ENV.EXPORT_PDF?.toLowerCase();

export const IS_FOR_EXPORT_WORD = process.env.REACT_APP_ENVIRONMENT?.toLowerCase() === CONSTANTS.ENV.EXPORT_WORD?.toLowerCase();

export const IS_PREVIEW_MODE = process.env.REACT_APP_PREVIEW_MODE?.toLowerCase() === 'true' && !IS_FOR_EXPORT_PDF && !IS_FOR_EXPORT_WORD;

export const IS_PUBLISHED_APP = process.env.REACT_APP_PREVIEW_MODE?.toLowerCase() === 'false' && !IS_FOR_EXPORT_PDF && !IS_FOR_EXPORT_WORD;

export const PUBLIC_TP_URL = 'https://beta.transparency.gov.au/';

export const URL_QUERY_KEYS = {
  SEARCH_TERM: 'q',
  PAGE_NUMBER: 'page',
  FILTER_DENOTE: '$',
  FILTER_VALUES_SPLIT_BY: ';',
};

export const CHART_TYPE = {
  BAR: 'bar',
  STACKED_BAR: 'stackedBar',
  TIME_PLOT: 'timeSeriesPlot',
  TABLE: 'table',
};